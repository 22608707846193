.label {
  position: relative;
  margin-bottom: 10px;
}
.label span {
  position: absolute;
  bottom: -20px;
  left: 0;
  color: red;
  font-size: 13px;
}
/* input:invalid[error="true"]{
    border: 1px solid red;
  }
  
  input:invalid[error="true"] ~ span {
    display: block;
  } 
   */
@media screen and (max-width: 768px) {
  .label span {
    bottom: -20px;
  }
}
