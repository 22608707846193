.footer {
  width: 100%;
  background-color: $color-white;
  margin-bottom: 3rem;
  &-container {
    // display: grid;
    // grid-template-columns:  300px repeat(4, 1fr);
    // column-gap: 30px;
    @include respond(tab-land) {
      grid-template-columns: repeat(4, 1fr);
    }
    @media (max-width: 600px) {
      grid-template-columns: repeat(2, 1fr);
      row-gap: 30px;
      text-align: center;
    }
  }

  &-section-1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @include respond(tab-land) {
      grid-column: 1/-1;
      align-items: center;
      margin-bottom: 5rem;
    }

    @media (max-width: 800px) {
      flex-direction: column;
    }
  }

  &-img-box {
    display: block;
    width: 100px;
    height: auto;
  }

  &-img {
    width: 100%;
    height: 100%;
  }
  &-text {
    // width: 100%;
    @include respond(tab-land) {
      text-align: center;
    }
  }
  &-socials {
    // width: 40%;
    display: flex;
    justify-content: space-between;

    &-links {
      margin-right: 2rem;
    }
  }

  &-links {
    display: flex;
    flex-direction: column;
  }
  &-link {
    &:link,
    &:visited {
      text-decoration: none;
      font-size: 1rem;
      line-height: 24px;
      margin-right: 2rem;
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
    &:focus {
      outline: none;
    }
  }
}

img {
  color: #738796;
}
