.service {
  border: 1px solid #eff2f8;
  border-radius: 10px;
  padding-bottom: 1.5rem;
  transition: all 0.3s ease-in;
  height: auto;
  align-self: start;
  cursor: pointer;

  &:hover {
    box-shadow: 5px 5px 20px #ccc;
    transform: scale(1.02);
  }

  &:last-child {
    @include respond(tab-port) {
      grid-column: 3 / span 1;
    }
    @include respond(phone) {
      grid-column: initial;
    }
  }
  &-container {
    width: 90%;
    padding: 1rem 0 0 0;
    display: flex;
    flex-direction: column;
    height: auto;
  }

  &-box {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    border-radius: 50%;
    width: 100px;
    height: 100px;

    @media (max-width: 700px) {
      height: 100px;
    }
  }

  &-img {
    width: 40%;
    height: auto;
    filter: opacity(.5) drop-shadow(0 0 0 red);
  }

  &-subtitle {
    text-align: center !important;
  }

  &-content {
    text-align: justify;
    display: flex;
    flex-basis: 100%;
    flex-direction: column;
    height: 100%;
  }

  &-read {
    cursor: pointer;
  }

  &-less {
    height: 0;
    transition: 0.3s all ease-in-out;
    opacity: 0;
    display: none;
  }
}
.hide2 {
  transition: 0.3s all ease-in-out;
  height: auto;
  opacity: 1;
  visibility: visible;
}
.hide {
  display: none;
}
