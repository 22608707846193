.home {
  width: 100%;
  font-size: 1rem;
  &-container {
    width: 100%;
    position: relative;
  }
  &-header {
    width: 100%;
    background-color: $color-primary;
    padding: 5rem 1.8rem 6.35rem 1.8rem;
    background-repeat: no-repeat;
    background-size: cover;

    @include respond(phone) {
      padding-bottom: 70px;
    }

    @media screen and (max-width: 310px) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      padding-bottom: 2rem;
    }

    &-container {
      display: flex;
      width: 100%;
    }

    &-content {
      align-self: center;
      flex-basis: 80%;
      max-width: 60%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow: hidden;
      @include respond(phone) {
        flex-basis: 100%;
        max-width: 100%;
      }
    }
    &-carousel {
      margin-bottom: 3rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    &-svg {
      flex-shrink: 2;
      @include respond(phone) {
        display: none;
      }
    }
    &-img {
      width: 100%;
    }
  }

  &-main {
    &-partners {
      width: 100%;
      position: relative;

      &-container {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: 200px;
        justify-content: space-between;
        column-gap: 20px;

        @media (max-width: 800px) {
          grid-template-columns: repeat(3, 1fr);
          grid-template-rows: none;
        }
      }

      &-list {
        display: flex;
        &:not(:first-child) {
          flex-direction: column;
          align-items: center;
        }
        &-5 {
          @media (max-width: 800px) {
            grid-column: 2 / -1;
          }
        }

        &:last-child > img {
          width: 30%;
        }
      }

      &-img {
        width: 45%;
        height: 100%;
      }
    }

    &-about {
      width: 100%;
      position: relative;
      justify-content: center;

      &-container {
        display: flex;
        padding: 2rem;

        @media (max-width: 800px) {
          flex-direction: column;
        }
      }

      &-box {
        // flex-shrink: 2;
        align-self: start;
        max-width: 500px;

        @media screen and (max-width: 800px) {
          margin-right: 0 !important;
          align-self: center;
        }
      }

      &-img {
        height: auto;
        width: 100%;
        object-fit: cover;
      }

      &-content {
        // flex-shrink: 0;
        align-self: center;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        @media (max-width: 800px) {
          margin-top: 2rem;
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        & > h3 {
          @include respond(phone) {
            text-align: center;
          }
        }

        // & > a{
        //  @media (max-width: 955px){
        //    padding: 1rem 2rem !important;
        //  }

        // }
      }
      &-text {
        @media screen and (max-width: 800px) {
          text-align: justify;
        }
      }

      &-button {
        @media screen and (max-width: 800px) {
          align-self: center !important;
        }
      }
    }

    &-services {
      background-color: $background-1;
      width: 100%;
      position: relative;

      &-container {
        padding-top: 1.5rem;
      }

      &-intro {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &-list {
        display: grid;
        grid-template-columns: repeat(auto-fit, 30%);
        column-gap: 30px;
        row-gap: 30px;
        padding-bottom: 2rem;

        @include respond(tab-port) {
          grid-template-columns: repeat(3, 1fr);
        }

        @include respond(phone) {
          grid-template-columns: repeat(2, 1fr);
        }
        @media (max-width: 500px) {
          grid-template-columns: repeat(1, 100%);
        }
        @media (max-width: 350px) {
          grid-template-columns: repeat(1, 100%);
        }
      }
    }
    &-customer {
      width: 100%;
      &-container {
        width: 100%;
        position: relative;
        @include respond(phone) {
        }
      }

      &-content {
        @include respond(phone) {
        }
      }

      &-title {
        @include respond(phone) {
          text-align: center;
        }
      }
      &-subtitle {
        width: 50%;
        @include respond(phone) {
          text-align: center;
          width: 100%;
        }
      }
      &-title {
        // width: 70%;
        @include respond(phone) {
          text-align: center;
          width: 100%;
        }
      }

      &-carousel {
        width: 100%;
        position: relative;
        overflow: hidden;

        &-container {
          display: flex;
          justify-content: space-between;
        }

        &-content {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          flex-basis: 60%;
          // p{
          //   width: 60%;
          // }
          @media (max-width: 700px) {
            align-items: center;
            flex-basis: 100%;
          }

          & > *:first-child {
            margin-bottom: auto;
            @include respond(phone) {
              margin-bottom: 3rem;
              font-size: 1rem;
            }
          }
        }

        &-imagebox {
          width: 267px;
          height: 130px;
          align-self: flex-end;
          position: relative;

          padding: 1rem;
          @include respond(phone) {
            display: none;
          }
        }

        &-img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        &-quotes {
          position: absolute;
          display: flex;
          bottom: -40px;
          left: -50px;
          width: 4rem;
          z-index: 100000;

          & > img {
            width: 100%;
          }
        }
      }

      &-dots {
        display: flex;
        @include respond(phone) {
          text-align: center;
          width: 100%;
          justify-content: center;
          margin-top: 3rem;
        }
      }

      &-dot {
        height: 10px;
        width: 10px;
        border-radius: 50%;
        cursor: pointer;
        background: rgba(115, 135, 150, 0.5);

        &:not(:last-child) {
          margin-right: 1rem;
        }
      }
    }
  }
}

.inactive {
  opacity: 0.5;
}

.active {
  background-color: $color-primary !important;
}
