.link {
  &:visited,
  &:link {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;

    display: flex;
    align-items: center;
    justify-content: center;
    color: #4c004b;
    text-decoration: none;
  }
  &:focus{
    outline: none;
  }
}
