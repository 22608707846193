.main {
  min-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border: 1px solid #eff2f8;
  border-radius: 10px;
  padding: 20px 30px;
  transition: 0.5s all ease-in!important;
}
.main:hover {
  background: #c9b2c9;
  transform: scale(1.08)!important;
}
.icons {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  transition: 0.5s all ease-in;
  background-color: #eff2f8;
  display: grid;
  place-items: center;
}
.icons > img {
  width: 30px;
  height: 30px;
}
.main h3 {
  font-size: 20px;
  color: #183b56;
  margin-top: 20px;
}
.main p {
  margin: 14px 0;
  color: #738796;
  line-height: 26px;
}
.main:hover p {
  color: #183b56;
}
.main a,
.main button {
  color: #4c004b;
  font-size: 14px;
  font-weight: 600;
}
.main button {
  border: none;
  background: transparent;
  cursor: pointer;
}
.main:hover button {
  /* color: #c9b2c9; */
}
.main:hover a {
  /* color: #c9b2c9; */
}
@media (max-width: 701px) {
  .main h3 {
    font-size: 18px;
  }
  .main p {
    font-size: 16px;
  }
  .main {
    padding: 20px;
  }
}
