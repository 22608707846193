$color-primary: #4b004b;
$color-secondary: #183b56;

$color-dark-purple: #4c004b;
$color-white: #ffffff;
$color-light-1: #738796;
$color-light-2: #999999;

$background-1: #fafdff;
$background-2: #fafdff 50%;
$background-3: #e5e5e5;

$font-family-primary: "Poppins", sans-serif;

$bg-color: $color-white;

