.Faq {
  &-header {
    &-container {
      background-color: white;
      width: 65%;
      margin: 0 auto 1rem auto;
      border-radius: 10px;
      padding: 0 0.6rem;
      display: flex;
      align-items: center;
      @media screen and(max-width: 800px) {
        width: 85%;
      }
    }

    &-input {
      margin-right: auto;
      width: 100%;
      height: 100%;
      padding: 1rem;
      border: none;
      font-size: 1.2rem;
      color: $color-light-2;
      &:focus {
        outline: none;
      }
    }

    &-button {
      padding: 0.7rem 2.5rem;
      background: #4b004b;
      border-radius: 5px;
      color: $color-white;
      text-decoration: none;
      cursor: pointer;
      border: none;
      font-size: 1rem;
      &:active,
      &:focus {
        outline: none;
      }
    }
  }

  &-main {
    width: 100%;
    // &-category {
    //   width: 100%;
    //   position: relative;

    //   &-subtitle {
    //     text-align: center;
    //     margin-bottom: 3rem;
    //   }

    //   &-plans {
    //     display: flex;
    //     @include respond(phone) {
    //       flex-wrap: wrap;
    //       justify-content: space-between;
    //     }
    //   }

    //   &-plan {
    //     text-align: center;
    //     border: 1px solid #eff2f8;
    //     border-radius: 10px;
    //     width: auto;
    //     display: flex;
    //     align-items: center;
    //     flex-direction: column;
    //     padding: 1rem 0;
    //     justify-content: space-between;

    //     &:not(:last-child) {
    //       margin-right: 2rem;
    //     }

    //     &:first-child {
    //       @media (max-width: 550px) {
    //         flex-basis: 40vh;
    //         margin: 0rem auto 0.5rem auto;
    //       }
    //     }
    //     &:nth-child(2) {
    //       @media (max-width: 550px) {
    //         flex-basis: 40vh;
    //         margin: 0.5rem auto 0.3rem auto;
    //       }
    //     }

    //     &:last-child {
    //       @include respond(phone) {
    //         flex-basis: 40vh;
    //         margin: 0.5rem auto 5rem auto;
    //         width: 50%;
    //       }
    //     }
    //   }

    //   &-box {
    //     width: 34px;
    //     background: #e5e5e5;
    //     height: 34px;
    //   }
    //   &-text {
    //     width: 80%;
    //     margin: auto;
    //   }
    // }

    &-faq {
      width: 100%;
      &-container {
        width: 80%;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (max-width: 600px){
          width: 90%;
        }
      }
      &-box {
        display: flex;
        flex-direction: column;
        width: 100%;
        &-container {
          transition: all 0.3s;
          background: #ffffff;
          border: 1px solid #eff2f8;
          border-radius: 10px;
          padding: 1rem 1rem 1rem 1rem;
          width: 100%;
          cursor: pointer;
          &:not(:last-child) {
            margin-bottom: 1rem;
          }
        }
      }

      &-question {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 1rem;
      }

      &-toggle {
        cursor: pointer;
        font-size: 2rem;
        margin-left: 10px;
      }
      &-answer {
        margin-top: 0.6rem;
        width: 70%;
        transition: all 0.3s;
        height: 0;
        opacity: 0;
        display: none;
        @media (max-width: 500px) {
          width: 100%;
        }
      }
    }

    &-question {
      width: 100%;

      &-helplines {
        display: grid;
        grid-template-columns: repeat(2, 30%);
        justify-content: space-around;
        @media screen and (max-width: 450px) {
          display: flex;
          flex-direction: column;
        }
      }

      &-helpline {
        background: #ffffff;
        border: 1px solid #eff2f8;
        box-sizing: border-box;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1rem 2rem;
        @media screen and (max-width: 450px) {
          width: 60%;
          margin: 2rem auto;
        }

        & > h4 {
          text-align: center;
        }
      }

      &-img {
        width: 60px;
        height: 60px;
        background-color: #4b004b;
        border-radius: 10px;
        margin-bottom: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.show {
  height: auto;
  opacity: 1;
  display: block;
}

.h {
  word-break: break-all;
}
