.container {
  width: 85%;
  margin: auto;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  position: relative;

  // @media screen and (max-width: 800px) {
  //   width: 90%; 
    
  // }
  // @include respond(phone) {
  // }
}

