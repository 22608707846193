.form {
  background: $color-white;
  box-shadow: 0px 20px 20px rgba(24, 59, 86, 0.1);
  border-radius: 10px;
  transform: translate(0%, -8%);
  padding: 2rem 2rem 3rem 2rem;
  margin: auto;
  border-radius: 10px;
  max-width: 800px;
  @include respond(phone) {
    max-width: 90%;
  }

  &-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 30px;
    row-gap: 30px;
    padding: 1rem;

    @media (max-width: 500px) {
      grid-template-columns: repeat(1, 100%);
    }
  }

  &-group {
    width: 100%;
    &:last-child {
      grid-column: 1 / 3;
      @media (max-width: 500px) {
        grid-column: 1 / -1;
      }
    }
    &:nth-child(5) {
      grid-column: 1 / 3;
      @media (max-width: 500px) {
        grid-column: 1 / -1;
      }
    }
  }

  &-label {
    font-family: $font-family-primary;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 24px;
    display: block;
    color: #738796;
    margin-bottom: 1rem;
  }

  &-input {
    width: 100%;
    border: 1px solid #eff2f8;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 1rem;
    font-family: inherit;
    font-size: 1rem;
    display: block;
    // &:last-child {
    //   max-width: 367.2px;
    // }
    &::-webkit-input-placeholder {
      color: $color-light-2;
    }
    &:focus {
      outline: none;
    }
  }

  &-textarea {
    width: 100%;
  }

  &-valid {
    border: 1px solid green;
  }

  &-invalid {
    border: 1px solid red;
    background-color: #fda49a;
  }
}
