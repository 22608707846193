.header {
  background-color: $color-primary;
  font-family: $font-family-primary;
  width: 100%;
  padding: 1.1rem 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999999;

  &-container {
    width: 90%;
    margin: auto;
    display: flex;
    align-items: center;
    position: relative;
  }

  &-logo {
    flex-basis: 20vh;
    margin-right: auto;
  }

  &-img {
    width: 100%;
  }

  &-toggle {
    display: none;;
    @include respond(phone) {
      align-self: center;
      display: block;
     
      z-index: 9999;
    }
  }
}
