*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
button,
input,
select {
  font-family: "Poppins", sans-serif;
}
@media screen and (max-width: 701px) {
}

/* @media (max-width: 75em) {
  html {
    font-size: 87.5%;
  }
}
@media (max-width: 56.25em) {
  html {
    font-size: 68.75%;
  }
}
@media (max-width: 43.75em) {
  html {
    font-size: 50%;
  }
}
@media (min-width: 112.5em) {
  html {
    font-size: 112.5%;
  }
} */
.App {
  overflow-x: hidden;
}
.app {
  width: 100%;
  position: relative;
  font-size: inherit;
}

.layout {
  width: 100%;
}
.layout-book {
  position: relative;
  width: 100%;
  margin-top: 5rem !important;
}

.main {
  position: relative;
  width: 100%;
  padding-top: 40px;
}

a {
  text-decoration: none;
}

.bg-text {
  font-family: "Poppins", sans-serif;
  font-size: 2.875rem;
  font-style: normal;
  font-weight: 600;
  color: #ffffff;
  line-height: 70px;
}
@media (max-width: 43.75em) {
  .bg-text {
    line-height: 30px;
  }
}

.sm-text {
  font-weight: normal;
  font-style: normal;
  font-size: 1rem;
  line-height: 33px;
  letter-spacing: 0.03em;
}
@media (max-width: 43.75em) {
  .sm-text {
    line-height: 25px;
    font-size: 1.5rem;
  }
}
.sm-text-white {
  color: #ffffff;
}
.sm-text-light {
  color: #738796;
}
.sm-text-dark {
  color: #183b56;
}

.title {
  font-weight: 500;
  font-size: 14px;
  font-style: normal;
  line-height: 21px;
  color: #4c004b;
  letter-spacing: 0.17rem;
}
@media (max-width: 43.75em) {
  .title {
    line-height: 11px;
  }
}

.subtitle {
  font-family: "Oswald", sans-serif !important;
  font-style: normal;
  font-weight: 600;
  color: #183b56;
}
.subtitle-big {
  font-size: 2.125rem;
  line-height: 51px;
}
@media (max-width: 43.75em) {
  .subtitle-big {
    line-height: 40px;
  }
}
.subtitle-small {
  line-height: 30px;
  font-size: 1.125rem;
}
@media (max-width: 43.75em) {
  .subtitle-small {
    line-height: 20px;
    font-size: 2rem;
  }
}
.subtitle-smaller {
  font-size: 0.8rem;
}
@media (max-width: 43.75em) {
  .subtitle-smaller {
    line-height: 20px;
    font-size: 1rem;
  }
}
.subtitle-xbig {
  font-size: 2.8125rem;
  line-height: 60px;
}
@media (max-width: 43.75em) {
  .subtitle-xbig {
    line-height: 45px;
  }
}

.mb-bg {
  margin-bottom: 6rem;
}

.mb-md {
  margin-bottom: 4rem;
}

.mb-sm {
  margin-bottom: 2rem;
}

.mb-xsm {
  margin-bottom: 1rem;
}

.mt-bg {
  margin-top: 6rem;
}

.mt-md {
  margin-top: 4rem;
}

.mt-sm {
  margin-top: 2rem;
}

.mt-xsm {
  margin-top: 1rem;
}

.mr-bg {
  margin-right: 6rem;
}

.mr-md {
  margin-right: 4rem;
}

.mr-sm {
  margin-right: 2rem;
}

.ml-bg {
  margin-left: 6rem;
}

.ml-md {
  margin-left: 4rem;
}

.ml-sm {
  margin-left: 2rem;
}

.ma {
  margin: auto;
}

.ta {
  text-align: center;
}

.button {
  cursor: pointer;
  padding: 1rem 3.4rem;
  border-radius: 30px;
  text-decoration: none;
  border: none;
  font-size: 1.125rem;
  line-height: 27px;
  font-weight: 500;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
}
.button-white {
  background-color: #ffffff;
  color: #4b004b;
  border: 1px solid #4b004b;
}
.button-white:hover {
  color: #ffffff;
  background-color: #4b004b;
  border: 1px solid #ffffff;
}
.button-purple {
  background-color: #4b004b;
  color: #ffffff;
}
.button-purple:hover {
  background-color: #ffffff;
  color: #4b004b;
  border: 1px solid #4b004b;
}
.button:focus {
  outline: none;
}
.button-span {
  margin-left: 10px;
  font-size: 2rem;
  margin-top: -4px;
}
.button:hover {
  transform: translateY(-3px);
}
.button:active {
  transform: translateY(3px);
}

.service {
  border: 1px solid #eff2f8;
  border-radius: 10px;
  padding-bottom: 1.5rem;
  transition: all 0.3s ease-in;
  height: auto;
  align-self: start;
  cursor: pointer;
}
.service:hover {
  box-shadow: 5px 5px 20px #ccc;
  transform: scale(1.02);
}
@media (max-width: 56.25em) {
  .service:last-child {
    grid-column: 3 / span 1;
  }
}
@media (max-width: 43.75em) {
  .service:last-child {
    grid-column: initial;
  }
}
.service-container {
  width: 90%;
  padding: 1rem 0 0 0;
  display: flex;
  flex-direction: column;
  height: auto;
}
.service-box {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  border-radius: 50%;
  width: 100px;
  height: 100px;
}
@media (max-width: 700px) {
  .service-box {
    height: 100px;
  }
}
.service-img {
  width: 40%;
  height: auto;
  filter: opacity(0.5) drop-shadow(0 0 0 red);
}
.service-subtitle {
  text-align: center !important;
}
.service-content {
  text-align: justify;
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  height: 100%;
}
.service-read {
  cursor: pointer;
}
.service-less {
  height: 0;
  transition: 0.3s all ease-in-out;
  opacity: 0;
  display: none;
}

.hide2 {
  transition: 0.3s all ease-in-out;
  height: auto;
  opacity: 1;
  visibility: visible;
}

.hide {
  display: none;
}

.link:visited,
.link:link {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4c004b;
  text-decoration: none;
}
.link:focus {
  outline: none;
}

.book {
  width: 100%;
}
.book-container {
  display: flex;
  align-items: center;
  padding-bottom: 7rem;
  border-bottom: 1px solid rgba(115, 135, 150, 0.5);
}
@media (max-width: 43.75em) {
  .book-container {
    flex-direction: column;
    align-self: center;
  }
}
.book-subtitle {
  flex-basis: 60%;
  margin-right: auto;
}
@media (max-width: 43.75em) {
  .book-subtitle {
    flex-basis: 100%;
    margin-right: 0;
    margin-bottom: 4rem;
    text-align: center;
    flex-grow: 1;
  }
}
.book-buttons {
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 43.75em) {
  .book-buttons {
    width: 100%;
    justify-content: space-around;
    align-items: center;
  }
}
@media (max-width: 260px) {
  .book-buttons {
    flex-direction: column;
  }
}
@media (max-width: 43.75em) {
  .book-buttons > a {
    text-align: center;
    align-self: center;
  }
}
@media (max-width: 385px) {
  .book-buttons > a {
    flex-basis: 100%;
  }
}
@media (max-width: 260px) {
  .book-buttons > a {
    margin-bottom: 2rem;
    margin-right: 0 !important;
  }
}

.form {
  background: #ffffff;
  box-shadow: 0px 20px 20px rgba(24, 59, 86, 0.1);
  border-radius: 10px;
  transform: translate(0%, -8%);
  padding: 2rem 2rem 3rem 2rem;
  margin: auto;
  border-radius: 10px;
  max-width: 800px;
}
@media (max-width: 43.75em) {
  .form {
    max-width: 90%;
  }
}
.form-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 30px;
  row-gap: 30px;
  padding: 1rem;
}
@media (max-width: 500px) {
  .form-container {
    grid-template-columns: repeat(1, 100%);
  }
}
.form-group {
  width: 100%;
}
.form-group:last-child {
  grid-column: 1/3;
}
@media (max-width: 500px) {
  .form-group:last-child {
    grid-column: 1/-1;
  }
}
.form-group:nth-child(5) {
  grid-column: 1/3;
}
@media (max-width: 500px) {
  .form-group:nth-child(5) {
    grid-column: 1/-1;
  }
}
.form-label {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 24px;
  display: block;
  color: #738796;
  margin-bottom: 1rem;
}
.form-input {
  width: 100%;
  border: 1px solid #eff2f8;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 1rem;
  font-family: inherit;
  font-size: 1rem;
  display: block;
}
.form-input::-webkit-input-placeholder {
  color: #999999;
}
.form-input:focus {
  outline: none;
}
.form-textarea {
  width: 100%;
}
.form-valid {
  border: 1px solid green;
}
.form-invalid {
  border: 1px solid red;
  background-color: #fda49a;
}

.header {
  background-color: #4b004b;
  font-family: "Poppins", sans-serif;
  width: 100%;
  padding: 1.1rem 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 20;
}
.header-container {
  width: 90%;
  margin: auto;
  display: flex;
  align-items: center;
  position: relative;
}
.header-logo {
  flex-basis: 20vh;
  margin-right: auto;
}
.header-img {
  width: 100%;
}
.header-toggle {
  display: none;
}
@media (max-width: 43.75em) {
  .header-toggle {
    align-self: center;
    display: block;
    z-index: 9999;
  }
}

.navigation {
  font-family: "Poppins", sans-serif;
  position: relative;
}
@media (max-width: 43.75em) {
  .navigation {
    display: none;
  }
}
.navigation-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
}
.navigation-item {
  font-size: 1rem;
  font-weight: 400;
}
.navigation-item:not(:last-child) {
  margin-right: 3rem;
}
.navigation-link:visited,
.navigation-link:link {
  text-decoration: none;
  color: #999999;
}
.navigation-link:hover {
  color: white;
}
.navigation-link-sayhi {
  font-weight: 600;
  color: #ffffff !important;
}

.footer {
  width: 100%;
  background-color: #ffffff;
  margin-bottom: 3rem;
}
@media (max-width: 75em) {
  .footer-container {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (max-width: 600px) {
  .footer-container {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 30px;
    text-align: center;
  }
}
.footer-section-1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media (max-width: 75em) {
  .footer-section-1 {
    grid-column: 1/-1;
    align-items: center;
    margin-bottom: 5rem;
  }
}
@media (max-width: 800px) {
  .footer-section-1 {
    flex-direction: column;
  }
}
.footer-img-box {
  display: block;
  width: 100px;
  height: auto;
}
.footer-img {
  width: 100%;
  height: 100%;
}
@media (max-width: 75em) {
  .footer-text {
    text-align: center;
  }
}
.footer-socials {
  display: flex;
  justify-content: space-between;
}
.footer-socials-links {
  margin-right: 2rem;
}
.footer-links {
  display: flex;
  flex-direction: column;
}
.footer-link:link,
.footer-link:visited {
  text-decoration: none;
  font-size: 1rem;
  line-height: 24px;
  margin-right: 2rem;
}
.footer-link:link:not(:last-child),
.footer-link:visited:not(:last-child) {
  margin-bottom: 1rem;
}
.footer-link:focus {
  outline: none;
}

img {
  color: #738796;
}

.container {
  width: 85%;
  margin: auto;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  position: relative;
}
@media screen and (max-width: 800px) {
  .container {
    width: 90%;
    margin: 0 auto;
  }
}
/* @media screen and (max-width: 701px) {
  .container {
    width: 100%;
  }
} */

.backgroundColor {
  background-color: #4b004b;
  padding: 5rem 0 10rem 0;
  text-align: center;
}

.sidebar {
  transition: width 3s ease-in-out;
  background-color: white;
  width: 65%;
  position: fixed;
  top: 72px;
  right: 0;
  z-index: 999;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
}
@media (min-width: 700px) {
  .sidebar {
    width: 0;
    opacity: 0;
    visibility: hidden;
  }
}
@media (max-width: 348px) {
  .sidebar {
    width: 100%;
  }
}
.sidebar-nav {
  padding: 4rem 2rem;
  width: 100%;
  margin: auto;
}
.sidebar-list {
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  column-gap: 25px;
  row-gap: 20px;
  list-style: none;
}
.sidebar-item {
  width: 100%;
  font-size: 1.5rem;
  font-weight: 400;
}
.sidebar-item-say {
  background-color: #4b004b;
  grid-column: 1/-1;
  padding: 1rem;
  border-radius: 10px;
  text-align: center;
}
.sidebar-item-say > a {
  color: #ffffff !important;
}
.sidebar-link:visited,
.sidebar-link:link {
  text-decoration: none;
  color: #4b004b;
}

.tar {
  text-align: right;
}

.home {
  width: 100%;
  font-size: 1rem;
}
.home-container {
  width: 100%;
  position: relative;
}
.home-header {
  width: 100%;
  background-color: #4b004b;
  padding: 5rem 1.8rem 6.35rem 1.8rem;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 43.75em) {
  .home-header {
    padding-bottom: 70px;
  }
}
@media screen and (max-width: 310px) {
  .home-header {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding-bottom: 2rem;
  }
}
.home-header-container {
  display: flex;
  width: 100%;
}
.home-header-content {
  align-self: center;
  flex-basis: 80%;
  max-width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}
@media (max-width: 43.75em) {
  .home-header-content {
    flex-basis: 100%;
    max-width: 100%;
  }
}
.home-header-carousel {
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.home-header-svg {
  flex-shrink: 2;
}
@media (max-width: 43.75em) {
  .home-header-svg {
    display: none;
  }
}
.home-header-img {
  width: 100%;
}
.home-main-partners {
  width: 100%;
  position: relative;
}
.home-main-partners-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 200px;
  justify-content: space-between;
  align-items: center;
  column-gap: 20px;
}
@media (max-width: 800px) {
  .home-main-partners-container {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: none;
  }
}
.home-main-partners-list {
  display: flex;
}
.home-main-partners-list:not(:first-child) {
  flex-direction: column;
  align-items: center;
}
@media (max-width: 800px) {
  .home-main-partners-list-5 {
    grid-column: 2/-1;
  }
}
.home-main-partners-list:last-child > img {
  width: 35%;
}
.home-main-partners-img {
  width: 80%;
}
.home-main-about {
  width: 100%;
  position: relative;
  justify-content: center;
}
.home-main-about-container {
  display: flex;
  padding: 2rem;
}
@media (max-width: 800px) {
  .home-main-about-container {
    flex-direction: column;
  }
}
.home-main-about-box {
  align-self: start;
  max-width: 500px;
}
@media screen and (max-width: 800px) {
  .home-main-about-box {
    margin-right: 0 !important;
    align-self: center;
  }
}
.home-main-about-img {
  height: auto;
  width: 100%;
  object-fit: cover;
}
.home-main-about-content {
  align-self: center;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
@media (max-width: 800px) {
  .home-main-about-content {
    margin-top: 2rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media (max-width: 43.75em) {
  .home-main-about-content > h3 {
    text-align: center;
  }
}
@media screen and (max-width: 800px) {
  .home-main-about-text {
    text-align: justify;
  }
}
@media screen and (max-width: 800px) {
  .home-main-about-button {
    align-self: center !important;
  }
}
.home-main-services {
  background-color: #fafdff;
  width: 100%;
  margin: 0 auto;
  font-size: 16px !important;
  position: relative;
}
.home-main-services-container {
  padding-top: 1.5rem;
}
.home-main-services-intro {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
}
.home-main-services-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 3fr));
  grid-gap: 20px;
  margin: 0 auto;
}
@media screen and (max-width: 701px) {
  .home-main-services {
    width: 100%;
  }
  .home-main-services-intro {
    width: 100%;
  }
  html {
    font-size: 10px;
  }
  .home-main-services-list {
    grid-template-columns: repeat(auto-fill, minmax(26rem, 1fr));
  }
}

/* @media (max-width: 56.25em) {
  .home-main-services-list {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 43.75em) {
  .home-main-services-list {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 500px) {
  .home-main-services-list {
    grid-template-columns: repeat(1, 100%);
  }
}
@media (max-width: 350px) {
  .home-main-services-list {
    grid-template-columns: repeat(1, 100%);
  }
} */
.home-main-customer {
  width: 100%;
}
.home-main-customer-container {
  width: 100%;
  position: relative;
}
@media (max-width: 43.75em) {
  .home-main-customer-title {
    text-align: center;
  }
}
.home-main-customer-subtitle {
  width: 50%;
}
@media (max-width: 43.75em) {
  .home-main-customer-subtitle {
    text-align: center;
    width: 100%;
  }
}
@media (max-width: 43.75em) {
  .home-main-customer-title {
    text-align: center;
    width: 100%;
  }
}
.home-main-customer-carousel {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.home-main-customer-carousel-container {
  display: flex;
  justify-content: space-between;
}
.home-main-customer-carousel-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-basis: 60%;
}
@media (max-width: 700px) {
  .home-main-customer-carousel-content {
    align-items: center;
    flex-basis: 100%;
  }
}
.home-main-customer-carousel-content > *:first-child {
  margin-bottom: auto;
}
@media (max-width: 43.75em) {
  .home-main-customer-carousel-content > *:first-child {
    margin-bottom: 3rem;
    font-size: 1rem;
  }
}
.home-main-customer-carousel-imagebox {
  width: 267px;
  height: 130px;
  align-self: flex-end;
  position: relative;
  padding: 1rem;
}
@media (max-width: 43.75em) {
  .home-main-customer-carousel-imagebox {
    display: none;
  }
}
.home-main-customer-carousel-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.home-main-customer-carousel-quotes {
  position: absolute;
  display: flex;
  bottom: -40px;
  left: -50px;
  width: 4rem;
  z-index: 100000;
}
.home-main-customer-carousel-quotes > img {
  width: 100%;
}
.home-main-customer-dots {
  display: flex;
}
@media (max-width: 43.75em) {
  .home-main-customer-dots {
    text-align: center;
    width: 100%;
    justify-content: center;
    margin-top: 3rem;
  }
}
.home-main-customer-dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  cursor: pointer;
  background: rgba(115, 135, 150, 0.5);
}
.home-main-customer-dot:not(:last-child) {
  margin-right: 1rem;
}

.inactive {
  opacity: 0.5;
}

.active {
  background-color: #4b004b !important;
}

.contact {
  width: 100%;
}
.contact-container {
  position: relative;
}
.contact-main {
  background: #ffffff;
}
.contact-main-form {
  position: relative;
}
.contact-main-support {
  width: 100%;
}
.contact-main-support-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 3rem;
  justify-content: space-between;
}
@media (max-width: 550px) {
  .contact-main-support-container {
    grid-template-columns: repeat(1, 100%);
  }
}
.contact-main-support-box {
  background: #ffffff;
  border: 1px solid #babfca;
  border-radius: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 1rem;
}
@media (max-width: 550px) {
  .contact-main-support-box {
    margin: 2rem auto !important;
    border: none;
  }
}
.contact-main-support-box-img {
  width: 60px;
  height: 60px;
  background-color: #4b004b;
  border-radius: 10px;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact-main-support-button {
  background-color: transparent !important;
  color: #4b004b !important;
  border: 1px solid #4b004b !important;
  align-self: center !important;
  flex-shrink: 0;
}

.about {
  width: 100%;
}
.about-container {
  position: relative;
}
.about-header-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 5rem;
}
.about-statements {
  width: 100%;
  font-family: "Poppins", sans-serif;
}
.about-statements-container {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 800px) {
  .about-statements-container {
    text-align: center;
    flex-direction: column;
  }
}
.about-statements-statement:first-child {
  flex-shrink: 2;
}
@media (max-width: 800px) {
  .about-statements-statement:first-child {
    margin-bottom: 5rem;
    flex-shrink: 1;
    margin-right: 0;
  }
}
.about-statements-statement:not(:last-child) {
  margin-right: 3rem;
}
@media (max-width: 800px) {
  .about-statements-statement:not(:last-child) {
    margin-right: 0;
  }
}
.about-statements-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
@media (max-width: 800px) {
  .about-statements-list {
    display: flex;
    flex-direction: column;
    text-align: left !important;
  }
}
.about-statements-item:not(:last-child) {
  margin-bottom: 0.8rem;
}
@media (max-width: 800px) {
  .about-statements-item > p {
    display: flex;
    justify-content: flex-start;
    white-space: pre-wrap;
  }
}
.about-statements-text {
  width: 100%;
  font-style: italic;
  font-weight: 500 !important;
  font-size: 24px !important;
  line-height: 50px;
}
.about-story {
  margin-top: 5rem;
}
.about-story-list {
  margin: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: max-content max-content max-content max-content;
  column-gap: 4rem;
  row-gap: 2rem;
}
@media screen and (max-width: 800px) {
  .about-story-list {
    grid-template-columns: repeat(1, 100%);
    grid-template-rows: repeat(4, max-content);
    row-gap: 5rem;
    column-gap: none;
  }
}
@media screen and (max-width: 800px) {
  .about-story-item {
    grid-column: 1/-1;
  }
}
.about-story-item p {
  line-height: 35px;
}
.about-story-item:nth-child(2) {
  grid-row: 1/3;
}
@media screen and (max-width: 800px) {
  .about-story-item:nth-child(2) {
    grid-row: 2/3;
  }
}
.about-story-item:nth-child(3) {
  grid-column: 1/2;
  grid-row: 2/4;
}
@media screen and (max-width: 800px) {
  .about-story-item:nth-child(3) {
    grid-row: 3/4;
  }
}
.about-story-item:last-child {
  grid-row: 3/5;
}
@media screen and (max-width: 800px) {
  .about-story-item:last-child {
    grid-row: 4/5;
  }
}
.about-gallery {
  width: 100%;
  position: relative;
}
.about-gallery-container {
  display: grid;
  grid-template-areas: "... one one one two  two  three three" "... one one one two  two three three " "... one one one five five five  ..." "four four four four five five five ..." "four four four four five five five ...";
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(5, 10vw);
  column-gap: 10px;
  row-gap: 10px;
}
@media screen and (max-width: 450px) {
  .about-gallery-container {
    display: flex;
    flex-direction: column;
  }
}
.about-gallery-list-1 {
  grid-area: one;
}
.about-gallery-list-2 {
  grid-area: two;
}
.about-gallery-list-3 {
  grid-area: three;
}
.about-gallery-list-4 {
  grid-area: four;
}
.about-gallery-list-5 {
  grid-area: five;
}
.about-gallery-img {
  width: 100%;
  position: relative;
  height: 100%;
  object-fit: cover;
  display: block;
  transition: all 0.3s;
}
.about-gallery-img:hover {
  transform: scale(0.9);
  z-index: 99;
}
.about-values {
  width: 100%;
}
.about-values-container {
  width: 80%;
  margin: auto;
}
.about-values-title {
  text-align: center;
  margin-top: 1rem;
}
.about-values-text {
  text-align: center;
}
@media (max-width: 43.75em) {
  .about-values-text {
    font-size: 1.2rem;
  }
}
.about-values-images {
  display: flex;
  justify-content: space-around;
}
@media screen and (max-width: 450px) {
  .about-values-images {
    display: none;
  }
}
.about-values-images-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.about-values-img {
  width: 100%;
  padding: 1rem;
}
.about-values-box {
  width: 64px;
  height: 64px;
  border-radius: 10px;
  background: rgba(75, 0, 75, 0.2);
  opacity: 0.5;
  display: flex;
  place-items: center;
}
.about-values-img {
  height: 90%;
  width: 90%;
}
.about-team {
  width: 100%;
}
.about-team-container {
  width: 90%;
  margin: auto;
}
.about-team-gallery {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 650px) {
  .about-team-gallery {
    flex-direction: column;
  }
}
.about-team-box {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 50%;
  flex-grow: 1;
}
.about-team-box:not(:last-child) {
  margin-right: 1rem;
}
@media screen and (max-width: 650px) {
  .about-team-box {
    margin-bottom: 2rem;
  }
}
.about-team-box h3 {
  margin-top: -6px !important;
  text-transform: capitalize !important;
  font-weight: 500 !important;
}
@media screen and (max-width: 800px) {
  .about-team-box h3 {
    font-size: 15px !important;
    font-weight: 400 !important;
  }
}
.about-team-box p {
  font-weight: bolder !important;
}
.about-team-img {
  margin: auto;
  height: 100%;
  position: relative;
  transition: all 0.3s;
}
@media screen and (max-width: 900px) {
  .about-team-img {
    width: 100%;
  }
}
@media screen and (min-width: 900px) {
  .about-team-img {
    width: 400px;
  }
}
.about-team-img img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
@media (max-width: 920px) {
  .about-team-img img {
    width: 100% !important;
    height: 290px !important;
  }
}
.about-team-img:hover {
  transform: scale(0.9);
}

.special {
  align-self: center !important;
}

.Faq-header-container {
  background-color: white;
  width: 65%;
  margin: 0 auto 1rem auto;
  border-radius: 10px;
  padding: 0 0.6rem;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 800px) {
  .Faq-header-gg {
    width: 85%;
  }
}
.Faq-header-input {
  margin-right: auto;
  width: 100%;
  height: 100%;
  padding: 1rem;
  border: none;
  font-size: 1.2rem;
  color: #999999;
}
.Faq-header-input:focus {
  outline: none;
}
.Faq-header-button {
  padding: 0.7rem 2.5rem;
  background: #4b004b;
  border-radius: 5px;
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
  border: none;
  font-size: 1rem;
}
.Faq-header-button:active,
.Faq-header-button:focus {
  outline: none;
}
.Faq-main {
  width: 100%;
}
.Faq-main-faq {
  width: 100%;
}
.Faq-main-faq-container {
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 600px) {
  .Faq-main-faq-container {
    width: 90%;
  }
}
.Faq-main-faq-box {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.Faq-main-faq-box-container {
  transition: all 0.3s;
  background: #ffffff;
  border: 1px solid #eff2f8;
  border-radius: 10px;
  padding: 1rem 1rem 1rem 1rem;
  width: 100%;
  cursor: pointer;
}
.Faq-main-faq-box-container:not(:last-child) {
  margin-bottom: 1rem;
}
.Faq-main-faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 1rem;
}
.Faq-main-faq-toggle {
  cursor: pointer;
  font-size: 2rem;
  margin-left: 10px;
}
.Faq-main-faq-answer {
  margin-top: 0.6rem;
  width: 70%;
  transition: all 0.3s;
  height: 0;
  opacity: 0;
  display: none;
}
@media (max-width: 500px) {
  .Faq-main-faq-answer {
    width: 100%;
  }
}
.Faq-main-question {
  width: 100%;
}
.Faq-main-question-helplines {
  display: grid;
  grid-template-columns: repeat(2, 30%);
  justify-content: space-around;
}
@media screen and (max-width: 450px) {
  .Faq-main-question-helplines {
    display: flex;
    flex-direction: column;
  }
}
.Faq-main-question-helpline {
  background: #ffffff;
  border: 1px solid #eff2f8;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 2rem;
}
@media screen and (max-width: 450px) {
  .Faq-main-question-helpline {
    width: 60%;
    margin: 2rem auto;
  }
}
.Faq-main-question-helpline > h4 {
  text-align: center;
}
.Faq-main-question-img {
  width: 60px;
  height: 60px;
  background-color: #4b004b;
  border-radius: 10px;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.show {
  height: auto;
  opacity: 1;
  display: block;
}

.h {
  word-break: break-all;
}

