.button {
  cursor: pointer;
  padding: 1rem 3.4rem;
  border-radius: 30px;
  text-decoration: none;
  border: none;
  font-size: 1.125rem;
  line-height: 27px;
  font-weight: 500;
  transition: all 0.3s;
  display: flex;
  align-items: center;

  justify-content: center;
  align-self: flex-start;
  &-white {
    background-color: $color-white;
    color: $color-primary;
    border: 1px solid $color-primary;
    &:hover {
      color: $color-white;
      background-color: $color-primary;
      border: 1px solid $color-white;
    }
  }

  &-purple {
    background-color: $color-primary;
    color: $color-white;

    &:hover {
      background-color: $color-white;
      color: $color-primary;
      border: 1px solid $color-primary;
    }
  }

  &:focus {
    outline: none;
  }

  &-span {
    margin-left: 10px;
    font-size: 2rem;
    margin-top: -4px;
  }

  &:hover {
    transform: translateY(-3px);
  }

  &:active {
    transform: translateY(3px);
  }
}
