.bg-text {
  font: {
    family: $font-family-primary;
    size: 2.875rem;
    style: normal;
    weight: 600;
  }
  color: $color-white;
  line-height: 70px;

  @include respond(phone) {
    line-height: 30px;
  }
}

.sm-text {
  font: {
    weight: normal;
    style: normal;
    size: 1rem;
  }
  line-height: 33px;
  letter-spacing: 0.03em;

  @include respond(phone) {
    line-height: 25px;
    font-size: 1.5rem;
  }

  &-white {
    color: $color-white;
  }

  &-light {
    color: $color-light-1;
  }

  &-dark {
    color: #183b56;
  }
}

.title {
  font: {
    weight: 500;
    size: 14px;
    // family: "AlternateGothic", "san-serif" !important;
    style: normal;
  }
  // @include font-face;
  line-height: 21px;
  color: $color-dark-purple;
  letter-spacing: 0.17rem;

  @include respond(phone) {
    line-height: 11px;
  }
}

.subtitle {
  font : {
    family: "Oswald", sans-serif !important;
    style: normal;
    weight: 600;
  }
  color: $color-secondary;

  &-big {
    font-size: 2.125rem;
    line-height: 51px;

    @include respond(phone) {
      line-height: 40px;
    }
  }

  &-small {
    line-height: 30px;
    font-size: 1.125rem;

    @include respond(phone) {
      line-height: 20px;
      font-size: 2rem;
    }
  }

  &-smaller {
    font-size: 0.8rem;
    @include respond(phone) {
      line-height: 20px;
      font-size: 1rem;
    }
  }

  &-xbig {
    font-size: 2.8125rem;
    line-height: 60px;

    @include respond(phone) {
      line-height: 45px;
    }
  }
}
