.about {
  width: 100%;

  &-container {
    position: relative;
  }
  &-header-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 5rem;
  }
  &-statements {
    width: 100%;
    font-family: $font-family-primary;
    &-container {
      display: flex;
      justify-content: space-between;

      @media (max-width: 800px) {
        text-align: center;
        flex-direction: column;
      }
    }

    &-statement {
      &:first-child {
        flex-shrink: 2;
        @media (max-width: 800px) {
          margin-bottom: 5rem;
          flex-shrink: 1;
          margin-right: 0;
        }
      }
      &:not(:last-child) {
        margin-right: 3rem;
        @media (max-width: 800px) {
          margin-right: 0;
        }
      }
    }

    &-list {
      padding: 0;
      margin: 0;
      list-style-type: none;
      @media (max-width: 800px) {
        display: flex;
        flex-direction: column;
        text-align: left !important;
      }
    }

    &-item {
      &:not(:last-child) {
        margin-bottom: 0.8rem;
      }
      @media (max-width: 800px) {
        & > p {
          display: flex;
          justify-content: flex-start;
          white-space: pre-wrap;
        }
      }
    }

    &-text {
      width: 100%;
      font-style: italic;
      font-weight: 500 !important;
      font-size: 24px !important;
      line-height: 50px;
    }
  }

  &-story {
    margin-top: 5rem;

    &-list {
      // width: 90%;
      margin: auto;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      // grid-template-rows: 333px 140px 193px 140px;
      grid-template-rows: max-content max-content max-content max-content;
      column-gap: 4rem;
      row-gap: 2rem;

      @media screen and (max-width: 800px) {
        grid-template-columns: repeat(1, 100%);
        grid-template-rows: repeat(4, max-content);
        row-gap: 5rem;
        column-gap: none;
      }
    }

    &-item {
      @media screen and (max-width: 800px) {
        grid-column: 1 / -1;
      }
      p {
        //   font-size: 16px;
        line-height: 35px;
      }

      &:nth-child(2) {
        grid-row: 1 /3;
        @media screen and (max-width: 800px) {
          grid-row: 2 / 3;
        }
      }

      &:nth-child(3) {
        grid-column: 1 / 2;
        grid-row: 2 / 4;
        @media screen and (max-width: 800px) {
          grid-row: 3 / 4;
        }
      }

      &:last-child {
        grid-row: 3/5;
        @media screen and (max-width: 800px) {
          grid-row: 4/ 5;
        }
      }
    }

    // &-img {
    //   width: 70px;
    //   height: 60px;
    //   background: #4b004b;
    //   margin-bottom: 1rem;
    // }
  }

  &-gallery {
    width: 100%;
    position: relative;

    &-container {
      display: grid;
      grid-template-areas:
        "... one one one two  two  three three"
        "... one one one two  two three three "
        "... one one one five five five  ..."
        "four four four four five five five ..."
        "four four four four five five five ...";
      grid-template-columns: repeat(8, 1fr);
      grid-template-rows: repeat(5, 10vw);

      column-gap: 10px;
      row-gap: 10px;
      @media screen and (max-width: 450px) {
        display: flex;
        flex-direction: column;
      }
    }

    &-list {
      &-1 {
        grid-area: one;
      }

      &-2 {
        grid-area: two;
      }

      &-3 {
        grid-area: three;
      }

      &-4 {
        grid-area: four;
      }
      &-5 {
        grid-area: five;
      }
    }

    &-img {
      width: 100%;
      position: relative;
      height: 100%;
      object-fit: cover;
      display: block;
      transition: all 0.3s;
      &:hover {
        transform: scale(0.9);
        z-index: 99;
      }
    }
  }

  &-values {
    width: 100%;

    &-container {
      width: 80%;
      margin: auto;
    }

    &-title {
      text-align: center;
      margin-top: 1rem;
    }

    &-text {
      text-align: center;
      @include respond(phone) {
        font-size: 1.2rem;
      }
    }

    &-images {
      display: flex;
      justify-content: space-around;

      @media screen and (max-width: 450px) {
        display: none;
      }

      &-container {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    &-img {
      width: 100%;
      padding: 1rem;
    }

    &-box {
      width: 64px;
      height: 64px;
      border-radius: 10px;
      background: rgba(75, 0, 75, 0.2);
      opacity: 0.5;
      display: flex;
      place-items: center;
    }
    &-img {
      height: 90%;
      width: 90%;
    }
  }

  &-team {
    width: 100%;

    &-container {
      width: 90%;
      margin: auto;
    }

    &-gallery {
      width: 90%;
      margin: auto;

      display: flex;
      justify-content: center;
      @media screen and (max-width: 650px) {
        flex-direction: column;
      }
    }

    &-box {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-basis: 50%;
      flex-grow: 1;
      &:not(:last-child) {
        margin-right: 1rem;
      }

      @media screen and (max-width: 650px) {
        margin-bottom: 2rem;
      }
      h3 {
        margin-top: -6px !important;
        text-transform: capitalize !important;
        font-weight: 500 !important;
        @media screen and (max-width: 800px) {
          font-size: 15px !important;
          font-weight: 400 !important;
        }
      }

      p {
        font-weight: bolder !important;
      }
    }

    &-img {
      margin: auto;
      height: 100%;
      position: relative;
      transition: all 0.3s;

      @media screen and (max-width: 900px) {
        width: 100%;
      }


      @media screen and (min-width: 900px) {
        width: 400px;
      }
      img {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        // object-fit: cover;
        @media (max-width: 920px) {
          width: 100% !important;
          height: 290px !important;
        }
      }

      &:hover {
        transform: scale(0.9);
      }
    }
  }
}
.special {
  align-self: center !important;
}
