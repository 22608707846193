.contact {
  width: 100%;
  &-container {
    position: relative;
  }

  &-main {
    background: $bg-color;
    &-form {
      position: relative;
    }

    &-support {
      width: 100%;
      &-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 3rem;
        justify-content: space-between;
        @media (max-width: 550px) {
         grid-template-columns: repeat(1, 100%);
        }
      }

      &-box {
        $bg-color : $color-white !global;
        background: $bg-color;
        border: 1px solid #babfca;
        border-radius: 10px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        padding: 1rem;

        @media (max-width: 550px) {
          margin: 2rem auto !important;
          border: none;
        }
      }

      &-box-img {
        $size: 60px;
        width: $size;
        height: $size;
        background-color: $color-primary;
        border-radius: 10px;
        margin-bottom: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &-button {
        background-color: transparent !important;
        color: $color-primary !important;
        border: 1px solid $color-primary !important;
        align-self: center !important;
        flex-shrink: 0;
      }
    }
  }
}
