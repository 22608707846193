.navigation {
  font-family: $font-family-primary;
  position: relative;

  @include respond(phone) {
    display: none;
  }

  &-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
  }

  &-item {
    font-size: 1rem;
    font-weight: 400;
    &:not(:last-child) {
      margin-right: 3rem;
    }
  }

  &-link {
    &:visited,
    &:link {
      text-decoration: none;
      color: $color-light-2;
    }

    &:hover{
      color: white;
    }

    &-sayhi {
      font-weight: 600;

      color: $color-white !important;
    }
  }
}
