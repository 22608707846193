.main {
  width: 100%;
  display: grid;
  place-items: center;
  margin: 0 auto;
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(6.5px);
  height: 100vh;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
}
.modal {
  width: 50%;
  min-height: 50vh;
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid #eff2f8;
  position: relative;
  text-align: center;
  padding: 50px 20px 30px;
}
.modal button {
  display: grid;
  padding: 16px;
  place-items: center;
  background: transparent;
  transition: 0.5 ease-in;
  border: none;
  padding: 12px;
  position: absolute;
  cursor: pointer;
  top: 10px;
  right: 10px;
  z-index: 100;
}
.modal button:hover {
  transform: scale(1.2);
}
.modal h3 {
  margin: 20px 0;
  color: #183b56;
}
.modal p {
  color: #738796;
  line-height: 24px;
  width: 80%;
  margin: 0 auto;
}
.modalImage > img {
  width: 35px;
  height: 35px;
}
@media screen and (max-width: 1024px) {
  .modal {
    width: 70%;
  }
}
@media screen and (max-width: 768px) {
  .modal {
    width: 90%;
    min-height: 40vh;
    padding: 20px 16px;
  }
  .modal h3 {
    font-size: 16px !important;
  }
  .modal p {
    line-height: 24px;
    font-size: 14px !important;
    width: 100%;
  }
}
