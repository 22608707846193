.main {
  width: 85%;
  margin: 0 auto;
  margin-top: 100px;
}
.bizzContent {
  text-align: center;
  width: 100%;
  margin: 0 auto 100px;
}
.bizzContent span {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.17em;
  color: #4c004b;
}
.bizzContent h1 {
  font-weight: 600;
  font-size: 34px;
  width: 45%;
  line-height: 42px;
  margin: 10px auto 30px;
  color: #183b56;
}
.bizzContent p {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #738796;
  width: 90%;
  margin: 0 auto;
}
.bizzCard {
  width: 90%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(19rem, 3fr));
  grid-gap: 20px;
  justify-content: center;
}
@media screen and (max-width: 701px) {
  .main {
    width: 100%;
    margin-top: 50px;
  }
  .bizzContent span {
    font-size: 12px;
  }
  .bizzContent {
    width: 90%;
    margin: 0 auto 50px;
  }
  .bizzContent h1 {
    font-size: 24px;
    line-height: 30px;
    width: 100%;
  }
  .bizzContent p {
    width: 100%;
  }
  .bizzCard {
    grid-template-columns: repeat(auto-fill, minmax(26rem, 1fr)) !important;
  }
}
