.mb-bg {
  margin-bottom: 6rem;
}
.mb-md {
  margin-bottom: 4rem;
}

.mb-sm {
  margin-bottom: 2rem;
}

.mb-xsm {
  margin-bottom: 1rem;
}
.mt-bg {
  margin-top: 6rem;
}
.mt-md {
  margin-top: 4rem;
}

.mt-sm {
  margin-top: 2rem;
}

.mt-xsm {
  margin-top: 1rem;
}

.mr-bg {
  margin-right: 6rem;
}
.mr-md {
  margin-right: 4rem;
}

.mr-sm {
  margin-right: 2rem;
}

.ml-bg {
  margin-left: 6rem;
}
.ml-md {
  margin-left: 4rem;
}

.ml-sm {
  margin-left: 2rem;
}

.ma {
  margin: auto;
}


.ta{
  text-align: center;
  // width: 70%;
}