

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
  font-size: 100%; // 1rem = 16px = 100%
  box-sizing: border-box;
  font-family: $font-family-primary;

  @include respond(tab-land) {
    font-size: 87.5%;
  }

  @include respond(tab-port) {
    font-size: 68.75%; //11px
  }

  @include respond(phone) {
    font-size: 50%;
  }

  @include respond(big-desktop) {
    font-size: 112.5%;
  }
}

.app {
  width: 100%;
  position: relative;
  font-size: inherit;
}

.layout {
  width: 100%;

  &-book {
    position: relative;
    width: 100%;
    margin-top: 10rem !important;
  }
}

.main {
  position: relative;
  width: 100%;
  padding-top: 40px;
}

a {
  text-decoration: none;
}
