.main {
  width: 80%;
  margin: 70px auto 0;
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(auto-fill, minmax(25rem, 2fr));
}
.mapContent a {
  font-size: 14px;
  color: #738796;
  display: flex;
  align-items: center;
}
.mapContent a svg {
  margin-right: 20px;
}
.mapContent a:not(:last-child) {
  margin-bottom: 23px;
}
@media screen and (max-width: 768px) {
  .main {
    width: 90%;
    grid-template-columns: repeat(auto-fill, minmax(30rem, 2fr));
  }
  .mapContent svg {
    width: 30px;
  }
  .mapContent p:not(:last-child) {
    margin-bottom: 16px;
  }
}
