.main {
  width: 85%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.main h2 {
  width: 40%;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 50px;
  text-align: center;
  color: #183b56;
}
.main form {
  margin-top: 50px;
  width: 60%;
  display: grid;
}
.inputs {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 2fr));
  gap: 30px;
  margin-bottom: 30px;
}
.main label {
  font-size: 14px;
  color: #738796;
  margin-bottom: 10px;
}
.inputs input {
  margin-top: 10px;
}
.inputs input,
.main form select {
  width: 100%;
  border: 1px solid #eff2f8;
  border-radius: 10px;
  height: 50px;
  padding: 15px;
  outline: none;
}
.main form select {
  -webkit-appearance: none;
  background-image: url(../assets/arrow.svg);
  background-position: right 20px center;
  background-repeat: no-repeat;
  background-size: auto 20%;
}
.main form textarea {
  width: 100%;
  border: 1px solid #eff2f8;
  border-radius: 10px;
  padding: 15px;
  min-height: 100px;
  resize: none;
  outline: none;
}
.textarea {
  margin-top: 30px;
}
.inputs input::placeholder,
.main form select::placeholder,
.main form textarea::placeholder {
  font-family: "Poppins", sans-serif;
  color: #738796;
}
.btn {
  display: flex;
  justify-content: center;
}
.main form button {
  padding: 14px;
  background: #4b004b;
  border-radius: 30px;
  color: #ffffff;
  font-size: 16px;
  border: 2px solid #4b004b;
  transition: all.5s;
  outline: none;
  margin-top: 30px;
  cursor: pointer;
}
.main form button:hover {
  background-color: #ffffff;
  color: #4b004b;
  font-weight: 500;
}
.message {
  color: red;
  font-size: 13px;
}
.response {
  text-align: center;
  margin-top: 10px;
  color: #2bc155;
}
.error {
  text-align: center;
  margin-top: 10px;
  color: red;
}

@media screen and (max-width: 1100px) {
  .main form {
    width: 88%;
  }
  .inputs {
    grid-template-columns: repeat(auto-fill, minmax(20rem, 2fr));
  }
  .main h2 {
    width: 80%;
  }
}
@media screen and (max-width: 768px) {
  .main {
    width: 90%;
  }
  .main form {
    width: 100%;
  }
  .main h2 {
    width: 100%;
    font-size: 22px;
    line-height: 30px;
  }
  .inputs {
    grid-template-columns: repeat(auto-fill, minmax(30rem, 2fr)) !important;
    gap: 20px;
  }
  .textarea {
    margin-top: 20px;
  }
}
