@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media (max-width: 43.75em) {
      @content; //600px
    }
  }
  @if $breakpoint == tab-port {
    @media (max-width: 56.25em) {
      @content; //900px
    }
  }
  @if $breakpoint == tab-land {
    @media (max-width: 75em) {
      @content; //1200px
    }
  }
  @if $breakpoint == big-desktop {
    @media (min-width: 112.5em) {
      @content; //1800px
    }
  }
}

// @mixin font-face {

// }
